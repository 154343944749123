import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Image from "../../components/image/image";
import Iconify from "../../components/iconify";
import { pxToRem, remToPx } from "../../theme/typography";
import { useResponsive } from "../../hooks/use-responsive";
import { linkMess } from "../../constants/link";
import { useGetCategoriesQuery } from "../../core/category/category.query";
import { useRouter } from "../../routers/hooks";
import { paths } from "../../routers/paths";

const infomation = [
  "Về chúng tôi",
  "Chính sách bán hàng",
  "Chính sách đổi trả",
  "Hướng dẫn nạp tiền",
];
// const product = [
//   "sale liên quân tháng 4",
//   "thử vận may tài khoản",
//   "siêu giảm giá hàng ngày",
//   "tài khoản giá rẻ",
//   "nick liên quân giá tốt",
// ];

const param = {
  page_number: 1,
  page_size: 1000,
};

const Bottom = () => {
  const lgUp = useResponsive("up", "lg");

  const { data: dataCategories } = useGetCategoriesQuery(param);

  const [products, setProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();

  useEffect(() => {
    setIsLoading(true);
    const setTimeOut = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => {
      clearTimeout(setTimeOut);
    };
  }, []);

  useEffect(() => {
    if (!dataCategories) return;

    setProducts(dataCategories?.data?.filter((item) => item.parent));
  }, [dataCategories]);

  return (
    <Box
      bgcolor={"#1d1818"}
      color={"white"}
      sx={{
        ...(!lgUp && {
          pb: 8.25,
        }),
      }}
    >
      <Container
        sx={{
          maxWidth: "80rem !important",
          px: ".75rem !important",
        }}
      >
        <Box
          py={5}
          px={"0.5rem"}
          display={"grid"}
          gridTemplateColumns={{
            lg: "repeat(4, 1fr)",
            xs: "repeat(1, 1fr)",
          }}
          gap={"1.5rem"}
          columnGap={"1.5rem"}
        >
          <Stack spacing={2}>
            <Image
              src="/images/logo.png"
              alt="logo"
              sx={{
                height: 1,
                width: 1,
                maxWidth: 150,
                maxHeight: 50,
                "& .css-3j6ntj": {
                  objectFit: "fill",
                },
              }}
            />
            <Typography
              component="span"
              variant="subtitle2"
              color="white"
              fontWeight={"medium"}
            >
              Shop acc game liên quân uy tín giá rẻ, bảo hành trọn đời, nhiều
              minigame event hấp dẫn, rút quân huy tự động, hỗ trợ 24/24.
            </Typography>
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{ textTransform: "uppercase" }}
            >
              HỆ THỐNG BÁN ACC TỰ ĐỘNG ĐẢM BẢO UY TÍN VÀ CHẤT LƯỢNG.
            </Typography>
            <Typography component="span" variant="body2" color="#d4d4d8">
              Chúng tôi luôn lấy uy tín đặt trên hàng đầu đối với khách hàng, hy
              vọng chúng tôi sẽ được phục vụ các bạn. Cám ơn!
            </Typography>
            <List>
              <ListItem
                sx={{
                  cursor: "pointer",
                  p: 0,
                }}
              >
                <ListItemIcon
                  sx={{
                    mr: 1,
                  }}
                >
                  <Iconify
                    icon="bxs:chevron-right"
                    sx={{
                      color: "white",
                      width: 14,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Privacy Policy"
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </ListItem>
              <ListItem
                sx={{
                  cursor: "pointer",
                  p: 0,
                }}
              >
                <ListItemIcon
                  sx={{
                    mr: 1,
                  }}
                >
                  <Iconify
                    icon="bxs:chevron-right"
                    sx={{
                      color: "white",
                      width: 14,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Terms of Service"
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </ListItem>
            </List>
          </Stack>
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography
                variant="h5"
                textTransform={"uppercase"}
                fontWeight={"medium"}
              >
                THÔNG TIN CHUNG
              </Typography>
              <Box bgcolor={"#27272a"} borderRadius={0.5}>
                <List>
                  {infomation.map((item, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        p: 0,
                      }}
                    >
                      <ListItemButton
                        sx={{
                          px: 1,
                          py: 0.25,
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#27272a",
                            color: "primary.main",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            mr: 1,
                          }}
                        >
                          <Iconify
                            icon="bx:bx-chevron-right"
                            sx={{
                              width: 13,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={item}
                          primaryTypographyProps={{
                            variant: "body3",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Stack>
            <Stack spacing={1}>
              <Typography
                variant="h5"
                textTransform={"uppercase"}
                fontWeight={"medium"}
              >
                THỜI GIAN HỖ TRỢ:
              </Typography>
              <Typography component="span" variant="body3" color="#d4d4d8">
                Sáng: 8h00 -{">"} 11h30 | Chiều: 13h00 -{">"} 21h00
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={1}>
            <Typography
              variant="h5"
              textTransform={"uppercase"}
              fontWeight={"medium"}
            >
              SẢN PHẨM
            </Typography>
            <Box bgcolor={"#27272a"} borderRadius={0.5}>
              <List>
                {products.length > 0 &&
                  products
                    .map((item) => ({
                      ...item,
                      sort: Math.random(),
                    }))
                    .sort((a, b) => a.sort - b.sort)
                    .slice(0, 7)
                    .map((item, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          p: 0,
                        }}
                      >
                        <ListItemButton
                          sx={{
                            px: 1,
                            py: 0.25,
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#27272a",
                              color: "primary.main",
                            },
                          }}
                          onClick={() => {
                            router.push(
                              `${paths.account.list(item.id)}?type=${
                                item.account_type
                              }&game=${item.game_account_type.id}`
                            );
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              mr: 1,
                            }}
                          >
                            <Iconify
                              icon="bx:bx-chevron-right"
                              sx={{
                                width: 13,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={item.name}
                            primaryTypographyProps={{
                              variant: "body3",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
              </List>
            </Box>
          </Stack>
          <Stack spacing={1}>
            <Typography
              variant="h5"
              textTransform={"uppercase"}
              fontWeight={"medium"}
            >
              HỖ TRỢ KHÁCH HÀNG
            </Typography>
            <Link component={"a"} href={linkMess}>
              <Image
                src="/images/messenger-01.png"
                sx={{
                  width: 1,
                  height: 1,
                  maxWidth: 240,
                  "& .css-3j6ntj": {
                    objectFit: "fill",
                  },
                }}
              />
            </Link>
          </Stack>
        </Box>

        <Box
          textAlign={"center"}
          mt={"1rem"}
          pt={"0.5rem"}
          pb={1}
          color={"white"}
          sx={{
            borderTop: 1,
            borderTopColor: "#27272a",
            borderTopStyle: "dashed",
          }}
        >
          <Stack spacing={0.5}>
            <Typography
              sx={{
                fontSize: pxToRem(14),
              }}
            >
              © Copyright 2024
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: pxToRem(12),
              }}
            >
              Operated by <Link color={"primary"}>https://trumnicklq.com</Link>
              , All Rights Reserved
            </Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Bottom;
