import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Image from "../../components/image/image";
import SeachBar from "./SeachBar";
import { bgGradient } from "../../theme/css";
import Iconify from "../../components/iconify";
import { useResponsive } from "../../hooks/use-responsive";
import { pxToRem } from "../../theme/typography";
import { useBoolean } from "../../hooks/use-boolean";
import AuthDialog from "./AuthDialog";
import ExchangeDialog from "./ExchangeDialog";
import AccountPopover from "./AccountPopover";
import { RouterLink } from "../../routers/components";
import { HOME, paths } from "../../routers/paths";
import { useRouter } from "../../routers/hooks";
import TabExchangeDialog from "./TabExchangeDialog";

const Header = () => {
  const theme = useTheme();
  const lgUp = useResponsive("up", "lg");

  const router = useRouter();

  const exchange = useBoolean();
  const auth = useBoolean();

  const [formExchange, setFormExchange] = useState(null);

  const [token, setToken] = useState(sessionStorage.getItem("accessToken"));

  useEffect(() => {
    const interval = setInterval(() => {
      setToken(sessionStorage.getItem("accessToken"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!formExchange) return;
    // console.log(formExchange);
  }, [formExchange]);

  const handleOpenExchange = () => {
    if (!token) return auth.onTrue();
    exchange.onTrue();
  };

  return (
    <>
      <Container
        sx={{
          maxWidth: "80rem !important",
          px: ".75rem !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          gap={3}
        >
          <Box display={"flex"} alignItems={"center"} gap={2} width={1}>
            <Link component={RouterLink} href={HOME}>
              <Image
                src="/images/logo.png"
                alt="logo"
                sx={{
                  height: 1,
                  width: 1,
                  maxWidth: 180,
                  maxHeight: 60,
                  "& .css-3j6ntj": {
                    objectFit: "fill",
                  },
                }}
              />
            </Link>

            {/* {lgUp && <SeachBar />} */}
          </Box>
          <Box
            display={"flex"}
            justifyContent={"end"}
            alignItems={"center"}
            gap={2}
            width={2 / 3}
          >
            {/* {!lgUp && (
              <Button
                variant="text"
                color="error"
                sx={{
                  minWidth: 40,
                }}
                onClick={() => {
                  if (!token) return;
                  router.push(paths.customer.notification);
                }}
              >
                <Iconify
                  icon="bx:bell"
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              </Button>
            )} */}

            <Button
              sx={{
                ...bgGradient({
                  direction: "270deg",
                  startColor: "#ef4444",
                  endColor: "#ec4899",
                }),
                color: theme.palette.common.white,
                px: 2,
                width: 115,
              }}
              startIcon={
                <Iconify
                  icon="bxs:dollar-circle"
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              }
              onClick={handleOpenExchange}
            >
              <Typography
                sx={{
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: pxToRem(13),
                }}
              >
                Nạp tiền
              </Typography>
            </Button>

            {lgUp && (
              <>
                {/* <Button
                  variant="text"
                  color="error"
                  sx={{
                    minWidth: 40,
                  }}
                  onClick={() => {
                    if (!token) return;
                    router.push(paths.customer.notification);
                  }}
                >
                  <Iconify
                    icon="bx:bell"
                    sx={{
                      width: 24,
                      height: 24,
                    }}
                  />
                </Button> */}

                {token ? (
                  <AccountPopover />
                ) : (
                  <Button
                    variant="text"
                    color="error"
                    sx={{
                      minWidth: 40,
                    }}
                    onClick={auth.onTrue}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={0.5}
                    >
                      <Box
                        sx={{
                          p: 0.5,
                          borderRadius: 1,
                          border: `1px solid ${theme.palette.divider}`,
                        }}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Iconify
                          icon="bxs:user"
                          sx={{
                            width: 20,
                            height: 20,
                          }}
                        />
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{
                          textTransform: "none",
                          fontWeight: 600,
                          fontSize: pxToRem(13),
                        }}
                      >
                        Đăng nhập / Đăng ký
                      </Typography>
                    </Stack>
                  </Button>
                )}
              </>
            )}

            {!lgUp && token && <AccountPopover />}
          </Box>
        </Box>
      </Container>

      <AuthDialog open={auth.value} onClose={auth.onFalse} />

      <TabExchangeDialog open={exchange.value} onClose={exchange.onFalse} />
    </>
  );
};

export default Header;
