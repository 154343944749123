export const Cards = {
  VIETTEL: "VIETTEL",
  MOBIFONE: "MOBIFONE",
  VINAPHONE: "VINAPHONE",
  GARENA: "GARENA",
  ZING: "ZING",
  VIETNAMOBILE: "VIETNAMOBILE",
};

export const listCardType = [
  {
    image: "/images/viettel.png",
    value: "VIETTEL",
  },
  {
    image: "/images/vinaphone.png",
    value: "VINAPHONE",
  },
  {
    image: "/images/mobifone.png",
    value: "MOBIFONE",
  },
  {
    image: "/images/garena.png",
    value: "GARENA",
  },
  {
    image: "/images/zing.png",
    value: "ZING",
  },
  {
    image: "/images/vietnamobile.png",
    value: "VIETNAMOBILE",
  },
];

export const listPackage = [
  10000, 20000, 30000, 50000, 100000, 200000, 300000, 500000, 1000000,
];

export const ListBanner = [
  {
    id: 1,
    coverUrl: "/images/banner.jpeg",
    title: "banner 1",
  },
  {
    id: 2,
    coverUrl: "/images/banner-2.png",
    title: "banner 2",
  },
  {
    id: 3,
    coverUrl: "/images/banner-3.png",
    title: "banner 3",
  },
];

export const HOST_URL = "https://api.trumnicklq.com";

const STATUS = {
  PENDING: "pending",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
  INVALID_CARD: "invalid_card",
};

export const OPTION_STATUS = [
  {
    value: STATUS.PENDING,
    label: "Đang xử lý",
    color: "info",
  },
  {
    value: STATUS.COMPLETED,
    label: "Thành công",
    color: "success",
  },
  {
    value: STATUS.CANCELLED,
    label: "Hủy",
    color: "erorr",
  },
  {
    value: STATUS.INVALID_CARD,
    label: "Không hợp lệ",
    color: "error",
  },
];

export const TRANSACTION_TYPE = {
  RECHARGE: "recharge",
  PURCHASE_ACCOUNT: "purchase_account",
};

export const TRANSACTION_TYPE_OPTION = [
  {
    value: TRANSACTION_TYPE.RECHARGE,
    label: "Nạp tiền",
  },
  {
    value: TRANSACTION_TYPE.PURCHASE_ACCOUNT,
    label: "Mua tài khoản",
  },
];

export const RANK = {
  UNRANKED: "Unranked",
  IRON: "Iron",
  BRONZE: "Bronze",
  SILVER: "Silver",
  GOLD: "Gold",
  PLATINUM: "Platinum",
  DIAMOND: "Diamond",
  MASTER: "Master",
  GRANDMASTER: "Grandmaster",
  CHALLENGER: "Challenger",
  MONSTER: "Monster",
};

export const OPTION_RANK = [
  {
    value: RANK.UNRANKED,
    label: "Chưa có",
  },
  {
    value: RANK.IRON,
    label: "Sắt",
  },
  {
    value: RANK.BRONZE,
    label: "Đồng",
  },
  {
    value: RANK.SILVER,
    label: "Bạc",
  },
  {
    value: RANK.GOLD,
    label: "Vàng",
  },
  {
    value: RANK.PLATINUM,
    label: "Bạch kim",
  },
  {
    value: RANK.DIAMOND,
    label: "Kim cương",
  },
  {
    value: RANK.MASTER,
    label: "Tinh anh",
  },
  {
    value: RANK.GRANDMASTER,
    label: "Cao thủ",
  },
  {
    value: RANK.CHALLENGER,
    label: "Huyền thoại",
  },
  {
    value: RANK.MONSTER,
    label: "Chiến tướng",
  },
];

export const optionRangePrice = [
  {
    max: 100000,
  },
  {
    max: 2000000,
  },
  {
    min: 100000,
    max: 300000,
  },
  {
    min: 300000,
    max: 500000,
  },
  {
    min: 500000,
    max: 1000000,
  },
  {
    min: 1000000,
    max: 2000000,
  },
  {
    min: 2000000,
    max: 4000000,
  },
  {
    min: 4000000,
    max: 8000000,
  },
  {
    min: 8000000,
    max: 12000000,
  },
  {
    min: 12000000,
  },
];
